<template>
  <div class="page-home">
    <div :style="{ width: '100%' }">
      <t-swiper :duration="300" :interval="3000">
        <t-swiper-item>
          <div style="margin-left:1.0938rem;font-family: 'YouSheBiaoTiHei';">
            <div style="font-size:.6077rem;color: #FFF;position: absolute;margin-top: 1.3542rem;"> 通信融合</div>

            <div style="font-size:.6077rem;color: #FFF;position: absolute;margin-top:2.0313rem;margin-left: 1.1979rem;">
              智行未来</div>

          </div>

          <img class="swiper-image" :src="require('../assets/xxw1.webp')" style="height: 4.9896rem;width: 100%;" />
        </t-swiper-item>



        <t-swiper-item>

          <div
            style="margin-left:.9375rem;font-family: 'YouSheBiaoTiHei';margin-top:1.526rem;position: absolute;width:6.25rem;background-color: red;font-size: .3906rem;">
            <div style="color: #FFF;position: absolute;margin-top:.0781rem;"> 清晰指引</div>


            <div style="color: #FFF;position: absolute;margin-top:.5625rem;">智能调控</div>

            <div style="color: #FFF;position: absolute;margin-top:1.0417rem"> 为出行保驾护航</div>

            <!-- <div style="color: #FFF;font-size: .2188rem;position: absolute;margin-top:2.6042rem">可变情报板系列</div> -->

          </div>





          <img class="swiper-image" :src="require('../assets/xxw2.webp')" style="height:4.9896rem;width: 100%;" />

        </t-swiper-item>


        <t-swiper-item>
          <div
            style="margin-left:1.0417rem;font-family: 'YouSheBiaoTiHei';margin-top:2.1719rem;position: absolute;width:6.25rem;background-color: red;font-size: .3854rem;">
            <div style="color: #FFF;position: absolute;"> 探索人体感应技术边界</div>


            <div style="color: #FFF;position: absolute;margin-top:.4427rem;"> 迈向智慧家居新时代</div>



            <!-- <div style="color: #FFF;font-size: .2188rem;position: absolute;margin-top:2.6042rem">可变情报板系列</div> -->

          </div>
          <img class="swiper-image" :src="require('../assets/xxw3.webp')" style="height: 4.9896rem;width: 100%;" />
        </t-swiper-item>



        <t-swiper-item>
          <div
            style="margin-left:.9896rem;font-family: 'YouSheBiaoTiHei';margin-top:2.1615rem;position: absolute;width:6.25rem;background-color: red;font-size:.4167rem;">
            <div style="color: #FFF;position: absolute;"> 全天候高精度检测</div>


            <div style="color: #FFF;position: absolute;margin-top:.4167rem;"> 助力智慧交通车路协同</div>



            <!-- <div style="color: #FFF;font-size: .2188rem;position: absolute;margin-top:2.6042rem">可变情报板系列</div> -->

          </div>
          <img class="swiper-image" :src="require('../assets/xxw4.webp')" style="height: 4.9896rem;width: 100%;" />
        </t-swiper-item>
      </t-swiper>
    </div>
    <!-- <div style="width: 100%;height: 50px;background-color: #fff;"></div> -->




    <div class="page2" :class="{ 'show': show }" style="margin-top:-.0521rem;position: relative">


      <!-- <img src="../assets/fenghaikeji.png" style="width:100%;height: 678px;border:0px;display:block;"> -->
      <div style="width:100%;height: 3.5313rem; position: relative;">
        <video ref="video" src="../assets/2e472190d849db186d329f9e17d51663.mp4" autoplay muted loop
          style="border: 0px; display: block;height:3.5313rem ; position: absolute; top: 50%; right: 0; transform: translateY(-50%);"></video>
        <div style="width:100%;height: 3.5313rem; position: absolute; top: 0; left: 0;">
          <img src="../assets/456125.webp" alt="" style="width: 100%;height: 100%;">
        </div>
      </div>


      <div class="slide-into" :class="{ 'show': show }" style="margin-left:0.4167rem">
        <div style="transform: translateY(-0.625rem);">
          <!-- <p style="text-indent: 0.9375rem;">
           
            <span style="font-size: 0.4167rem;font-weight: bold;color: #0055ffc1;">创新引领</span>
          </p> -->
          <!-- <p style="text-indent: 2.1875rem;"><span style="font-size: 0.4167rem;font-weight: bold;color: #0055ffc1;">智慧未来
            </span>
          </p> -->

          <p style="text-indent: .5729rem;margin-top:.5208rem;"><span
              style="font-size: 0.4167rem;font-weight: bold;color: #0055ffc1;">创立于2007年
            </span>
          </p>
        </div>

      </div>




      <div class="hover1-div"
        style="position:absolute;bottom: 10%;left: 10%;font-size: 0.0833rem;font-weight: 1000;color: #5B5B5B;">
        <div style="transform:translateY(.1563rem) translateX(-.2604rem);" class="ooodiv">
          <p style="text-indent:.3125rem;">
            <img src="../assets/fenghai8.webp" alt="" style="width: 2.2708rem;transform: translateY(-15px);">
          </p>
          <p style="text-indent:.3125rem;">广州市丰海科技股份有限公司是电子通信领域的国家级专精特新“小巨人”高新技

          </p>


          <p style="text-indent:.3125rem;">术企业，主营业务为毫米波雷达、雷达通信模组、雷视融合系统、交通LED显示</p>
          <p style="text-indent:.3125rem;">设备、交通诱导发布及控制系统的研发、生产及销售。</p>
          <p style="text-indent:.3125rem;"></p>

          <p style="margin-left: 2.6042rem;" @click="goToAboutPage">
            <img @mouseover="changeImagePath" @mouseout="resetImagePath" :src="imagePath"
              style="margin-top:.625rem;margin-left: -2.3rem;width: .5729rem;transform: translateY(-.5208rem);" alt="">
          </p>

        </div>

      </div>
    </div>


    <div
      style="display: flex;position: relative;justify-content: center;align-items: center;margin-top:0px;position: relative;">
      <img src="../assets/shouye2.png" style="width: 100%; height: 3.3333rem;border:0px;display:block; ">

      <div style="position: absolute;left:1.0573rem;top: .3125rem;">
        <img src="../assets/shouye4.png" alt="" style="height: .5313rem;">

      </div>

      <div style="position: absolute;left: 1.0573rem;top: 1.0938rem;">
        <img src="../assets/shouye3.png" alt="" style="height: .9896rem;">

      </div>

      <div
        style="position: absolute;left:1.0573rem;top:2.2rem;font-size: .0729rem;width:4.29rem;text-indent: .1563rem;line-height: .1042rem;font-weight: bold;">
        <p>丰海科技-西电广研院智慧交通创新研究中心，是在《交通强国建设纲要》、《国家综合立体交通网规划纲要》的
          政策背景下，根据“面向国家战略需求、面向世界科技前沿”方针，由广州市丰海科技股份有限公司、西安电子科技大学
          广州研究院联合成立。</p>
        <p style="margin-top: .1563rem;transform: translateX(-0.1302rem);" @click="goToAboutPage16">
          <img @mouseover="changeImagPath" @mouseout="resetImagPath" :src="imagePathed" style="width: .5729rem;" alt="">
        </p>


      </div>
    </div>

    <div class="chanpin"
      style="width:100%;display: flex;justify-content: center;align-items: center;  flex-direction: column;">
      <div class="size" style="height:.4167rem;">

        <!-- Product Center -->
      </div>

      <div class="size2" style="position: relative;">
        <img style="margin: 0 auto;position: absolute;width: 1.5521rem;height:.1875rem ; left: -.4479rem;top:-.0938rem"
          src="../assets/Product Center.png" alt="">
        产品中心
      </div>
      <p
        style=" display: inline-block; height: .0104rem; width: .3333rem; background-color:  #407BF5 ; font-size: .1667rem ; color: #5B5B5B; font-family: PingFang SC, PingFang SC; font-weight: bold;margin-top: .0521rem;margin-bottom: .1042rem;">
      </p>


      <div class="page3" style="display: flex;  ">

        <div class="hover-div" @mouseover="chaImg" @mouseout="chaoutImg" @click="goToPro1"
          style="position:relative;overflow: hidden; ">

          <img src="../assets/mb1.webp" style="width:2.2292rem;height: 1.875rem; flex: 0 0 auto;">

          <div
            style="position:absolute;
             padding:.1042rem .1042rem .026rem; box-sizing: border-box;width: 100%;bottom: .026rem;background: linear-gradient(180deg, rgba(10, 10, 10, 0.5) 0%, #03134d 100%); opacity: 0.85;">
            <p style="height: .1302rem;"></p>
            <p class="pb" style="margin-top:.0417rem;font-size: .0729rem;font-weight: 400;color: #ccc;">
              针对雾天、夜晚及其他恶劣天气条件下道路行车安全保障
            </p>
          </div>

          <div style="position:absolute;
             padding:.1042rem .1042rem .026rem; box-sizing: border-box;width: 100%;bottom: .1563rem;">
            <p class="ko" style="color: #FFF;font-family: Arial, Helvetica, sans-serif;">交通诱导系列</p>

          </div>





        </div>


        <div class="hover-div" @click="goToPro2" @mouseover="chaImg" @mouseout="chaoutImg"
          style="margin-left: .0833rem;position:relative;overflow: hidden;">
          <img src="../assets/mb2.webp" style="width:4.5417rem;height: 1.875rem; flex: 0 0 auto;">
          <div
            style="position:absolute;
             padding:.1042rem .1823rem .026rem; box-sizing: border-box;width: 100%;bottom: .026rem;background: linear-gradient(180deg, rgba(10, 10, 10, 0.5) 0%, #03134d 100%); opacity: 0.85;">
            <p style="height: .1302rem;"></p>
            <p class="pb" style="margin-top:.0417rem;font-size: .0729rem;font-weight: 400;color: #ccc;">
              为高速公路用户收缴费提供方便、快捷、智能、友好的工作界面，同时提供更加方便和舒适的人性化收费环境</p>
          </div>


          <div style="position:absolute;
         padding:.1042rem .1042rem .026rem; box-sizing: border-box;width: 100%;bottom: .1563rem;">

            <p class="ko" style="color: #FFF;font-family: Arial, Helvetica, sans-serif;">智能收费亭系列</p>

          </div>




        </div>
      </div>

      <!-- <div class="page4" style="display: flex;  margin-top: 16px;">
       
        <div  @mouseover="chaImg"  @click="goToPro3" @mouseout="chaoutImg" style="position:relative;overflow: hidden;" class="hover-div">
          <img src="../assets/mb3.png" style="width:428px;height: 360px; flex: 0 0 auto;">
          <div  
            style="position:absolute; padding:20px 20px 5px; box-sizing: border-box;width: 100%;bottom: 5px;background: linear-gradient(180deg, rgba(255,255,255,0.5) 0%, #37405E 100%);opacity: 0.85;">
            <p >门架式可变情报板
            </p>
            <p  class="pb" style="margin-top:8px;font-size: 14px;font-weight: 400;color: #ccc;"> 高智能型 | 节能型 | 高兼容性 | 高可靠性 </p>
          </div>
         
        </div>



        <div @mouseover="chaImg"  @click="goToPro4" @mouseout="chaoutImg" style="position:relative;margin-left: 16px;;" class="hover-div" >
          <img src="../assets/mb6.png" style="width:428px;height: 360px; flex: 0 0 auto;">
          <div  
            style="position:absolute; padding:20px 35px 5px; box-sizing: border-box;width: 100%;bottom: 5px; ;background: linear-gradient(180deg, rgba(255,255,255,0.5) 0%, #37405E 100%);opacity: 0.85;">
            <p>悬臂式可变情报板</p>
            <p class="pb" style="margin-top:8px;font-size: 14px;font-weight: 400;color: #ccc;">高智能型 | 节能型 | 高兼容性 | 高可靠性</p>
          </div>         
        </div>

        <div @mouseover="chaImg"  @click="goToPro5" @mouseout="chaoutImg" style="position:relative;margin-left: 16px;" class="hover-div">
          <img src="../assets/mb4.png" style="width:428px;height: 360px; flex: 0 0 auto;">
          <div  
            style="position:absolute; padding:20px 35px 5px; box-sizing: border-box;width: 100%;bottom: 5px;background: linear-gradient(180deg, rgba(255,255,255,0.5) 0%, #37405E 100%);opacity: 0.85;">
            <p >费额显示器</p>
            <p  class="pb" style="margin-top:8px;font-size: 14px;font-weight: 400;color: #ccc;">高智能型 | 节能型 | 高兼容性 | 高可靠性</p>
          </div>
          
        </div>

      </div> -->

      <div class="page4" style="display: flex;   align-items: center; margin-top : .0833rem;">



        <div @mouseover="chaImg" @click="goToPro3" @mouseout="chaoutImg" style="position: relative;overflow: hidden;"
          class="hover-div">
          <img src="../assets/leidashouy.webp" style="width:2.2292rem;height: 1.875rem; flex: 0 0 auto;">

          <div
            style="position:absolute;
           padding:.1042rem .1042rem .026rem; box-sizing: border-box;width: 100%;bottom: .026rem;background: linear-gradient(180deg, rgba(10, 10, 10, 0.5) 0%, #03134d 100%); opacity: 0.85;">
            <p style="height: .1302rem;"></p>
            <p class="pb" style="margin-top:.0417rem;font-size: .0729rem;font-weight: 400;color: #ccc;">
              雷达技术设备系统，用于实现道路目标探测、跟踪、识别等
            </p>
          </div>

          <div style="position:absolute;
           padding:.1042rem .1042rem .026rem; box-sizing: border-box;width: 100%;bottom: .1563rem;">
            <p class="ko" style="color: #FFF;font-family: Arial, Helvetica, sans-serif;"> 雷达系列 </p>
          </div>




        </div>



        <div @mouseover="chaImg" @click="goToPro4" @mouseout="chaoutImg"
          style="position:relative; margin-left: .0833rem;;" class="hover-div">
          <img src="../assets/雷达通信模组.webp" style="width:2.2292rem;height: 1.875rem; flex: 0 0 auto;">
          <div
            style="position:absolute;
           padding:.1042rem .1042rem .026rem; box-sizing: border-box;width: 100%;bottom: .026rem;background: linear-gradient(180deg, rgba(10, 10, 10, 0.5) 0%, #03134d 100%); opacity: 0.85;">
            <p style="height: .1302rem;"></p>
            <p class="pb" style="margin-top:.0417rem;font-size: .0729rem;font-weight: 400;color: #ccc;">
              雷达技术实现信号接送，应用于检测、跟踪和通信等
            </p>
          </div>


          <div style="position:absolute;
           padding:.1042rem .1042rem .026rem; box-sizing: border-box;width: 100%;bottom: .1563rem;">
            <p class="ko" style="color: #FFF;font-family: Arial, Helvetica, sans-serif;">雷达通信模组</p>
          </div>


        </div>


        <div @mouseover="chaImg" @click="goToPro5" @mouseout="chaoutImg"
          style="position:relative;margin-left: .0833rem;" class="hover-div">
          <img src="../assets/照明系列3.webp" style="width:2.2292rem;height: 1.875rem; flex: 0 0 auto;">


          <div
            style="position:absolute;
           padding:.1042rem .1042rem .026rem; box-sizing: border-box;width: 100%;bottom: .026rem;background: linear-gradient(180deg, rgba(10, 10, 10, 0.5) 0%, #03134d 100%); opacity: 0.85;">
            <p style="height: .1302rem;"></p>
            <p class="pb" style="margin-top:.0417rem;font-size: .0729rem;font-weight: 400;color: #ccc;">
              高亮度、节能环保，提升道路行车安全性
            </p>
          </div>

          <div style="position:absolute;
           padding:.1042rem .1042rem .026rem; box-sizing: border-box;width: 100%;bottom: .1563rem;">
            <p class="ko" style="color: #FFF;font-family: Arial, Helvetica, sans-serif;">LED照明系列</p>
          </div>
        </div>
      </div>


      <div class="page4" style="display: flex;   align-items: center; margin-top: .0833rem;">
        <div @mouseover="chaImg" @click="goToPro51" @mouseout="chaoutImg" style="position:relative;overflow: hidden;"
          class="hover-div">
          <img src="../assets/可变情报板.webp" style="width:2.2292rem;height: 1.875rem; flex: 0 0 auto;">


          <div
            style="position:absolute;
           padding:.1042rem .1042rem .026rem; box-sizing: border-box;width: 100%;bottom: .026rem;background: linear-gradient(180deg, rgba(10, 10, 10, 0.5) 0%, #03134d 100%); opacity: 0.85;">
            <p style="height: .1302rem;"></p>
            <p class="pb" style="margin-top:.0417rem;font-size: .0729rem;font-weight: 400;color: #ccc;">
              实时显示交通信息、路况提示和警示信息，提高道路安全性
            </p>
          </div>

          <div style="position:absolute;
           padding:.1042rem .1042rem .026rem; box-sizing: border-box;width: 100%;bottom: .1563rem;">
            <p class="ko" style="color: #FFF;font-family: Arial, Helvetica, sans-serif;">可变情报板系列</p>
          </div>









        </div>



        <div @mouseover="chaImg" @click="goToPro52" @mouseout="chaoutImg"
          style="position:relative;margin-left: .0833rem;;" class="hover-div">
          <img src="../assets/收费车道系列.webp" style="width:2.2292rem;height: 1.875rem; flex: 0 0 auto;">


          <div
            style="position:absolute;
           padding:.1042rem .1042rem .026rem; box-sizing: border-box;width: 100%;bottom: .026rem;background: linear-gradient(180deg, rgba(10, 10, 10, 0.5) 0%, #03134d 100%); opacity: 0.85;">
            <p style="height: .1302rem;"></p>
            <p class="pb" style="margin-top:.0417rem;font-size: .0729rem;font-weight: 400;color: #ccc;">车辆通行管理
            </p>
          </div>

          <div style="position:absolute;
           padding:.1042rem .1042rem .026rem; box-sizing: border-box;width: 100%;bottom: .1563rem;">
            <p class="ko" style="color: #FFF;font-family: Arial, Helvetica, sans-serif;">收费车道设备系列</p>
          </div>

        </div>


        <div @mouseover="chaImg" @click="goToPro53" @mouseout="chaoutImg"
          style="position:relative;margin-left: .0833rem;" class="hover-div">
          <img src="../assets/LED显示标志.webp" style="width:2.2292rem;height: 1.875rem; flex: 0 0 auto;">


          <div
            style="position:absolute;
           padding:.1042rem .1042rem .026rem; box-sizing: border-box;width: 100%;bottom: .026rem;background: linear-gradient(180deg, rgba(10, 10, 10, 0.5) 0%, #03134d 100%); opacity: 0.85;">
            <p style="height: .1302rem;"></p>
            <p class="pb" style="margin-top:.0417rem;font-size: .0729rem;font-weight: 400;color: #ccc;">高亮度、节能环保、远距离可见
            </p>
          </div>

          <div style="position:absolute;
           padding:.1042rem .1042rem .026rem; box-sizing: border-box;width: 100%;bottom: .1563rem;">
            <p class="ko" style="color: #FFF;font-family: Arial, Helvetica, sans-serif;">LED显示标志</p>
          </div>

        </div>




      </div>


      <!-- <div class="page5" style="display: flex;   margin-top: 16px; ">
        <div @mouseover="chaImg"  @click="goToPro7" @mouseout="chaoutImg" style="position:relative;" class="hover-div">
          <img src="../assets/mb8.png"
            style="width:428px; height: 360px; flex: 0 0 auto;">
         
            <div  
            style="position:absolute; padding:20px 35px 5px; box-sizing: border-box;width: 100%;bottom: 5px;background: linear-gradient(180deg, rgba(255,255,255,0.5) 0%, #37405E 100%);">
            <p>交通劝导装置
            </p>
            <p  class="pb" style="margin-top:8px;font-size: 14px;font-weight: 400;color: #ccc;">应用于高速公路、城市道路等存在盲区的来车预警</p>
          </div>
          
        </div>

        <div @mouseover="chaImg"  @click="goToPro6" @mouseout="chaoutImg" style="position:relative;margin-left: 16px;" class="hover-div">
          <img src="../assets/mb7.png" style="width:872px; height: 360px; flex: 0 0 auto;">
          <div  
            style="position:absolute; padding:20px 20px 5px; box-sizing: border-box;width: 100%;bottom: 5px;background: linear-gradient(180deg, rgba(255,255,255,0.5) 0%, #37405E 100%);">
            <p >毫米波雷达系列
            </p>
            <p class="pb" style="margin-top:8px;font-size: 14px;font-weight: 400;color: #ccc;">探测距离可达350米，支持双向8车道多目标跟踪（可达256个）
            </p>
          </div>
          
        </div>


  

      </div> -->

    </div>

    <!-- 解决方案  -->





    <div
      style="width:100%;margin-bottom: .0781rem;display: flex;justify-content: center;  flex-direction: column;align-items: center;">

      <div class="size" style="height:.4167rem;">
      </div>

      <div class="size2" style="position: relative;">
        <img style="margin: 0 auto;position: absolute;width:1.5521rem ;height: .1875rem; left: -.4792rem;top:-.0938rem"
          src="../assets/total2.png" alt="">
        成功案例
      </div>
      <p
        style=" display: inline-block; height: .0104rem; width: .3333rem; background-color:  #407BF5 ; font-size: .1667rem ; color: #5B5B5B; font-family: PingFang SC, PingFang SC; font-weight: bold;margin-top: .0521rem;margin-bottom: .1042rem;">

      </p>
      <p
        style="font-size: .0729rem;font-weight: 400;color: #407BF5;font-size: .0729rem;margin-bottom: .0521rem;margin-top: -.026rem;">
        为您提供多方位高效的解决方案</p>
    </div>


    <div class="anli">
      <div style="width: 6.8542rem; height: 2.5938rem;display: flex;
justify-content: center;border-radius: 0 .0521rem .0521rem 0;">
        <div style="width: 2.7604rem; ">
          <div style="width: 100%;height: 0.7rem;border-bottom: .026rem solid #427CF4;display: flex;justify-content: center;
align-items: end;padding-bottom: .1042rem;box-sizing: border-box;">
            <p style="width: 75%;font-size: .2344rem;font-weight: bold;font-family: '微软雅黑';">{{
              selectedProject }}</p>
            <p style="width:25%;bottom: 0;" @click="navigateToProject(selectedLi)" @mouseover="trgoTo"
              @mouseout="trgoLve">
              <img :src="imgSrc" alt="" style="width: .5729rem;">
            </p>
          </div>
          <div>
            <ul class="anliUl">
              <li @click="selectProject('交通雷达')" :class="{ active: selectedLi === '交通雷达' }">交通雷达</li>
              <li style="margin-left: .2604rem;" @click="selectProject('雷达通信模组')"
                :class="{ active: selectedLi === '雷达通信模组' }">雷达通信模组
              </li>
              <li @click="selectProject('智能收费亭')" :class="{ active: selectedLi === '智能收费亭' }">智能收费亭</li>
              <li style="margin-left: .2604rem;" @click="selectProject('交通诱导系列')"
                :class="{ active: selectedLi === '交通诱导系列' }">交通诱导系列
              </li>
              <li @click="selectProject('可变信息情报板')" :class="{ active: selectedLi === '可变信息情报板' }">可变信息情报板
              </li>
              <li style="margin-left: .2604rem;" @click="selectProject('收费车道设备系列')"
                :class="{ active: selectedLi === '收费车道设备系列' }">
                收费车道设备系列</li>
              <li @click="selectProject('LED显示标志系列')" :class="{ active: selectedLi === 'LED显示标志系列' }">
                LED显示标志系列</li>
              <li style="margin-left: .2604rem;" @click="selectProject('LED照明系列')"
                :class="{ active: selectedLi === 'LED照明系列' }">LED照明系列
              </li>
            </ul>
          </div>
        </div>
        <div class="anlixiao" style="float: right">
          <img :src="selectedProjectImage" alt="" style="width:100%;height:100%;">
        </div>

      </div>
    </div>














    <div class="pressCenter"
      style="width:100%;background-color: white;display: flex;justify-content: center;  flex-direction: column;align-items: center;">
      <div class="size" style="height:.4167rem;">
      </div>
      <div class="size2">
        <p style="font-size: .1458rem;"> 新闻中心</p>
      </div>
      <p
        style=" display: inline-block; height: .0104rem; width: .3333rem; background-color:  #407BF5 ; font-size: .1667rem ; color: #5B5B5B; font-family: PingFang SC, PingFang SC; font-weight: bold;margin-top: .0521rem;margin-bottom: .2604rem;">
      </p>
    </div>


    <!-- <div style="height: 2.6042rem" class="newcen">
      <el-carousel :interval="4000" type="card" height="2.2708rem" style="transform: translateX(-0.7813rem)  translateY(-0.1406rem) ;padding:0 1.5625rem; width: 5.2083rem">

        <el-carousel-item>
          <div @click="gotoNewe">
          

            <img style="height: 1.5625rem;width: 100%;" src="../assets/美的 毫米单位.jpg" alt="">
            <div style="background-color:  #E9E9E9; transform: translateY(-0.0521rem);">
              <div style="padding: .1042rem .0781rem 1rem .0521rem;">
                <p style="font-weight: bold;color: #407BF5; font-size: .0938rem;line-height: .2083rem;">丰海科技正式成为美的供应商</p>
                <p style="font-weight: bold;color: #000000;line-height: .1354rem; font-size: .0938rem;">中国.广州 日期:2024年1月</p>
                <P style="font-size: .0625rem; color: #5B5B5B;line-height: .125rem;">
                </P>

              </div>
            </div>
          </div>
        </el-carousel-item>
        <el-carousel-item >
          <div @click="gotoNewe3">
        
            <img style="height: 1.5625rem;width: 100%;" src="../assets/算法大赛 毫米单位.jpg" alt="">
            <div style="background-color:  #E9E9E9;   transform: translateY(-0.0521rem);">
              <div style="padding: .1042rem .0781rem 1rem .0521rem;">
                <p style="font-weight: bold;color: #407BF5; font-size: .0938rem;line-height: .1302rem;">
                  鸣金收兵，丰海科技团队荣获第二届粤港澳大湾区（黄埔）国际算法算例大赛冠军！</p>
                <p style="font-weight: bold;color: #000000;line-height: .1354rem;font-size: .0938rem;">中国.广州 日期:2024年1月 </p>
                <P style="font-size: .0625rem; color: #5B5B5B;line-height: .125rem;">
                </P>

              </div>
            </div>
          </div>
        </el-carousel-item>

        <el-carousel-item>
          <div  @click="gotoNewe2">

            <img style="height: 1.5625rem;width: 100%;" src="../assets/牌匾 毫米单位.jpg" alt="">
            <div style="background-color:  #E9E9E9;  transform: translateY(-0.0521rem);">
              <div style="padding: .1042rem .0781rem 1rem .0521rem;">
                <p style="font-weight: bold;color: #407BF5; font-size: .0938rem;line-height: .2083rem;">丰海科技荣膺国家级专精特新“小巨人”企业称号
                </p>
                <p style="font-weight: bold;color: #000000;line-height: .1354rem;font-size: .0938rem;">中国.广州 日期:2023年07月</p>
                <P style="font-size: .0625rem; color: #5B5B5B;line-height: .125rem;"></P>
              </div>
            </div>
          </div>
        </el-carousel-item>
      </el-carousel>
      <p style="position: absolute;right: 2.1875rem;transform: translateY(-.1563rem);" @click="goToNewPage"> <img @mouseover="changeImagePath"
          @mouseout="resetImagePath" :src="imagePath" style="margin-top:-0.2604rem;margin-left: -0.026rem; width: .5729rem;" alt=""></p>

 
  </div> -->

    <div style="width: 100%;height: 3.125rem;background-color: #F0F0F0 ;">


      <div class="container" style="margin: 0 auto;">
        <div class="sub-container" ref="subContainer">
          <div class="sub-div" style="margin-left: .0677rem; position: relative;" @mouseover="hp1" @mouseleave="hl1"
            @click="gotoNw1">
            <div ref="firstChild0"
              style="background-size: contain; display: flex; flex-direction: column; padding: .0833rem; box-sizing: border-box; width: 2.2396rem; height: .625rem; position: absolute; bottom: 0rem; bottom: 1.4948rem;">
              <span ref="span0" style=" font-family: PingFang SC, PingFang SC;
            font-weight: 800;
            font-size: .125rem;
            color: #898989;width: 100%; height: .2552rem; border-bottom: .0104rem solid #898989;">2024.1.12</span>
              <p ref="pElement0" style="font-family: PingFang SC, PingFang SC;
              font-weight: bold;
              font-size: .125rem;
              color: #000000;
              width: 2.0833rem;
              overflow: hidden;
              text-overflow: ellipsis;
              margin: 0;
              padding: 0;
              height: .2083rem;
              line-height: .2083rem;
              white-space: nowrap;
              font-size: .125rem;">
                鸣金收兵，丰海科技团队荣获第二届粤港澳大湾区（黄埔）国际算法算例大赛冠军！</p>
            </div>
            <div style="position: absolute; bottom: 0; height: 1.4948rem; width: 2.2396rem; background-color: beige;">
              <img src="../assets/newspaper11.webp" alt="" style="width: 100%;height: 100%;">
            </div>
          </div>


          <div class="sub-div" style="position: relative;" @mouseover="hp2" @mouseleave="hl2" @click="gotoNw2">
            <div ref="firstChild1"
              style="display: flex; flex-direction: column; padding: .0833rem; box-sizing: border-box; width: 2.2396rem; height: .625rem;  position: absolute; bottom: 0rem; bottom: 1.4948rem;">
              <span ref="span1" style=" font-family: PingFang SC, PingFang SC;
            font-weight: 800;
            font-size: .125rem;
            color: #898989;width: 100%; height: .2552rem; border-bottom: .0104rem solid #898989;">2024.3.28</span>
              <p ref="pElement1"
                style=" font-family: PingFang SC, PingFang SC;
              font-weight: bold;
              font-size: .125rem;
              color: #000000; width: 2.0833rem; overflow: hidden; text-overflow: ellipsis; margin: 0; padding: 0; height: .2083rem; line-height: .2083rem; white-space: nowrap; font-size: .125rem;">
                丰海科技亮相第二十六届高速公路信息化大会

              </p>
            </div>
            <div style="position: absolute; bottom: 0; height: 1.4948rem; width: 2.2396rem; background-color: beige;">
              <img src="../assets/zhanhui3.png" alt="" style="width: 100%;height: 100%;">
            </div>
          </div>

          <div class="sub-div" style="position: relative;" @mouseover="hp3" @mouseleave="hl3" @click="gotoNw3">
            <div ref="firstChild2"
              style="display: flex; flex-direction: column; padding: .0833rem; box-sizing: border-box; width: 2.2396rem; height: .625rem; position: absolute; bottom: 0rem; bottom: 1.4948rem;">
              <span ref="span2" style=" font-family: PingFang SC, PingFang SC;
            font-weight: 800;
            font-size: .125rem;
            color: #898989;width: 100%; height: .2552rem; border-bottom: .0104rem solid #898989;">2024.1</span>
              <p ref="pElement2"
                style=" font-family: PingFang SC, PingFang SC;
            font-weight: bold;
            font-size: .125rem;
            color: #000000; width: 2.0833rem; overflow: hidden; text-overflow: ellipsis; margin: 0; padding: 0; height: .2083rem; line-height: .2083rem; white-space: nowrap; font-size: .125rem;">
                丰海科技正式成为美的集团供应商</p>
            </div>
            <div style="position: absolute; bottom: 0; height: 1.4948rem; width: 2.2396rem; background-color: beige;">
              <img src="../assets/美的11.png" alt="" style="width: 100%;height: 100%;">
            </div>

          </div>

          <div class="sub-div" style="position: relative;" @mouseover="hp4" @mouseleave="hl4" @click="gotoNw4">
            <div ref="firstChild3"
              style="display: flex; flex-direction: column; padding: .0833rem; box-sizing: border-box; width: 2.2396rem; height: .625rem;  position: absolute; bottom: 0rem; bottom: 1.4948rem;">
              <span ref="span3" style=" font-family: PingFang SC, PingFang SC;
            font-weight: 800;
            font-size: .125rem;
            color: #898989;width: 100%; height: .2552rem; border-bottom: .0104rem solid #898989;">2023.7.14</span>
              <p ref="pElement3"
                style=" font-family: PingFang SC, PingFang SC;
            font-weight: bold;
            font-size: .125rem;
            color: #000000; width: 2.0833rem; overflow: hidden; text-overflow: ellipsis; margin: 0; padding: 0; height: .2083rem; line-height: .2083rem; white-space: nowrap; font-size: .125rem;">
                丰海科技荣膺国家级专精特新“小巨人”企业称号</p>
            </div>
            <div style="position: absolute; bottom: 0; height: 1.4948rem; width: 2.2396rem; background-color: beige;">
              <img src="../assets/newspaper5.webp" alt="" style="width: 100%;height: 100%;">
            </div>
          </div>

          <div class="sub-div" style="position: relative;" @mouseover="hp5" @mouseleave="hl5" @click="gotoNw5">
            <div ref="firstChild4"
              style="display: flex; flex-direction: column; padding: .0833rem; box-sizing: border-box; width: 2.2396rem; height: .625rem; position: absolute; bottom: 0rem; bottom: 1.4948rem;">
              <span ref="span4" style=" font-family: PingFang SC, PingFang SC;
            font-weight: 800;
            font-size: .125rem;
            color: #898989;width: 100%; height: .2552rem; border-bottom: .0104rem solid #898989;">2023.5.30</span>
              <p ref="pElement4"
                style=" font-family: PingFang SC, PingFang SC;
            font-weight: bold;
            font-size: .125rem;
            color: #000000; width: 2.0833rem; overflow: hidden; text-overflow: ellipsis; margin: 0; padding: 0; height: .2083rem; line-height: .2083rem; white-space: nowrap; font-size: .125rem;">
                创新引领发展，丰海科技再获新专利</p>
            </div>
            <div style="position: absolute; bottom: 0; height: 1.4948rem; width: 2.2396rem; background-color: beige;">
              <img src="../assets/zhuanli111.png" alt="" style="width: 100%;height: 100%;">
            </div>
          </div>

          <!-- Add similar structures for other sub-divs -->

        </div>
        <!-- <button id="prevBtn" @click="prevSlide">Previous</button> -->
        <el-button type="primary" @click="prevSlide" id="prevBtn" icon="el-icon-arrow-left"></el-button>
        <!-- <button  @click="nextSlide">Next</button> -->
        <el-button type="primary" id="nextBtn" @click="nextSlide" icon="el-icon-arrow-right"></el-button>
      </div>

      <p style="position: absolute;right:1.6667rem;transform: translateY(-0.2604rem);" @click="goToNewPage"> <img
          @mouseover="changeImagePath" @mouseout="resetImagePath" :src="imagePath"
          style="margin-top:-0.2604rem;margin-left: -0.026rem; width: .5729rem;" alt=""></p>

    </div>












  </div>
</template>


<script>

  export default {
    name: 'PageHome',
    // 
    data() {
      return {

        currentIndex: 0,
        selectedLi: '交通雷达',
        selectedProject: '广州机场高速',
        selectedProjectLogo: require('../assets/xxLogo.webp'),
        selectedProjectImage: require('../assets/机场高速改.jpg'),
        imgSrc: require('../assets/xxLogo.webp'),
        projects: {
          '交通雷达': {
            name: '广州机场高速',

            image: require('../assets/机场高速改.jpg')
          },
          '雷达通信模组': {
            name: '美的433MHz网络模块组件',

            image: require('../assets/928412.webp')
          },
          '智能收费亭': {
            name: '黑龙江高速公路收费站改造',
            image: require('../assets/need1.webp')
          },
          '交通诱导系列': {
            name: '清远高速公路隐患路段',
            image: require('../assets/智能雾灯.webp')
          },
          '可变信息情报板': {
            name: '广东慧盐高速',
            image: require('../assets/need123.webp')
          },
          '收费车道设备系列': {
            name: '湖南大戛高速',
            image: require('../assets/need4.webp')
          },
          'LED显示标志系列': {
            name: '彪水岩隧道',
            image: require('../assets/LED显示8.webp')
          },
          'LED照明系列': {
            name: '安徽宿州中心',
            image: require('../assets/LEDzz照明.webp')
          }

        },


        paragraphStyle: {
          margin: '.0521rem'
          // 初始值
        },
        slideClass: '',
        tabs: [],
        tabContent: {},
        progressBarWidth: 0,

        interval: null,
        imagePath: require("../assets/xxLogo.png"),
        imagePathed: require("../assets/xxLogo.png"),
        isSelected: true,
        value1: 'first',
        show: false,


      };
    },
    created() {
      this.selectTab(0);
      this.startProgressBar();
      this.tabContent = this.tabs[this.currentTab];
      //
    },
    watch: {
      currentTab(newVal, oldVal) {
        this.progressBarWidth = 0;
        this.startProgressBar();
        if (newVal !== oldVal) {
          this.slideTextLeft();
        }
      }

    },
    mounted() {
      this.selectTab(0);
      this.startProgressBar();

      this.$nextTick(() => {
        this.slideElementsIn();
      });

      window.addEventListener('scroll', this.handleScroll);





    },

    methods: {

      gotoNw1(){
        this.$router.push({ path: '/separateNews24' });

      },
      gotoNw2(){
        this.$router.push({ path: '/separateNews25' });

      },
      gotoNw3(){
        this.$router.push({ path: '/separateNews23' });

      },
      gotoNw4(){
        this.$router.push({ path: '/separateNews20' });

      },
      gotoNw5(){
        this.$router.push({ path: '/separateNews18' });

      },
      
      hp1() {
        this.$refs.firstChild0.style.height = ".9792rem";
        this.$refs.firstChild0.style.fontSize = ".1667rem";
        this.$refs.pElement0.style.color = "white";
        this.$refs.pElement0.style.width = "2.0833rem";
        this.$refs.pElement0.style.whiteSpace = "normal";
        this.$refs.span0.style.fontSize = ".1667rem";
        this.$refs.span0.style.color = "#fff";
        this.$refs.span0.style.borderBottom = ".0104rem solid #FFFFFF";
        this.$refs.firstChild0.style.backgroundImage = `url(${require('../assets/xinw.png')})`;
        this.$refs.pElement0.style.height = ".5729rem";
        this.$refs.pElement0.style.lineHeight = ".2604rem";
        this.$refs.pElement0.textContent = "鸣金收兵，丰海科技团队荣获第二届粤港澳大湾区（黄埔）国际算法...";
      },

      hl1() {
        this.$refs.pElement0.style.color = "#000";
        this.$refs.firstChild0.style.height = ".625rem";
        this.$refs.firstChild0.style.fontSize = ".125rem";
        this.$refs.span0.style.fontSize = ".125rem";
        this.$refs.span0.style.color = "#898989";
        this.$refs.span0.style.borderBottom = ".0104rem solid #898989";
        this.$refs.pElement0.style.height = ".2083rem";
        this.$refs.pElement0.style.overflow = "hidden"; // Hide any overflowing content
        this.$refs.pElement0.style.textOverflow = "ellipsis"; // Add an ellipsis (...) for text that overflows
        this.$refs.pElement0.style.whiteSpace = "nowrap";
        this.$refs.pElement0.style.lineHeight = ".2083rem";
        this.$refs.pElement0.textContent = "鸣金收兵，丰海科技团队荣获第二届粤港澳大湾区（黄埔）国际算法算例大赛冠军！";
        this.$refs.firstChild0.style.backgroundImage = "none";
      },
      hp2() {
        this.$refs.firstChild1.style.height = ".9792rem";
        this.$refs.firstChild1.style.fontSize = ".1667rem";
        this.$refs.pElement1.style.color = "white";
        this.$refs.pElement1.style.width = "2.0833rem";
        this.$refs.pElement1.style.whiteSpace = "normal";
        this.$refs.span1.style.fontSize = ".1667rem";
        this.$refs.span1.style.color = "#fff";
        this.$refs.span1.style.borderBottom = ".0104rem solid #FFFFFF";

        this.$refs.firstChild1.style.backgroundImage = `url(${require('../assets/xinw.png')})`;
        this.$refs.pElement1.style.height = ".5729rem";
        this.$refs.pElement1.style.lineHeight = ".2604rem";



        this.$refs.pElement1.textContent = "丰海科技亮相第二十六届高速公路信息化大会";
      },
      hl2() {
        this.$refs.pElement1.style.color = "#000";
        this.$refs.firstChild1.style.height = ".625rem";
        this.$refs.firstChild1.style.fontSize = ".125rem";
        this.$refs.span1.style.fontSize = ".125rem";
        this.$refs.span1.style.color = "#898989";
        this.$refs.span1.style.borderBottom = ".0104rem solid #898989";
        this.$refs.pElement1.style.height = ".2083rem";
        this.$refs.pElement1.style.overflow = "hidden"; 
        this.$refs.pElement1.style.textOverflow = "ellipsis"; 
        this.$refs.pElement1.style.whiteSpace = "nowrap";
        this.$refs.pElement1.style.lineHeight = ".2083rem";
        this.$refs.pElement1.textContent = "丰海科技亮相第二十六届高速公路信息化大会";
        this.$refs.firstChild1.style.backgroundImage = "none";
      },
      hp3() {
        this.$refs.firstChild2.style.height = ".9792rem";
        this.$refs.firstChild2.style.fontSize = ".1667rem";
        this.$refs.pElement2.style.color = "white";
        this.$refs.pElement2.style.width = "2.0833rem";
        this.$refs.pElement2.style.whiteSpace = "normal";
        this.$refs.span2.style.fontSize = ".1667rem";
        this.$refs.span2.style.color = "#fff";
        this.$refs.span2.style.borderBottom = ".0104rem solid #FFFFFF";

        this.$refs.firstChild2.style.backgroundImage = `url(${require('../assets/xinw.png')})`;
        this.$refs.pElement2.style.height = ".5729rem";
        this.$refs.pElement2.style.lineHeight = ".2604rem";



        this.$refs.pElement2.textContent = "丰海科技正式成为美的集团供应商";
      },
      hl3() {
        this.$refs.pElement2.style.color = "#000";
        this.$refs.firstChild2.style.height = ".625rem";
        this.$refs.firstChild2.style.fontSize = ".125rem";
        this.$refs.span2.style.fontSize = ".125rem";
        this.$refs.span2.style.color = "#898989";
        this.$refs.span2.style.borderBottom = ".0104rem solid #898989";
        this.$refs.pElement2.style.height = ".2083rem";
        this.$refs.pElement2.style.overflow = "hidden"; // Hide any overflowing content
        this.$refs.pElement2.style.textOverflow = "ellipsis"; // Add an ellipsis (...) for text that overflows
        this.$refs.pElement2.style.whiteSpace = "nowrap";
        this.$refs.pElement2.style.lineHeight = ".2083rem";
        this.$refs.pElement2.textContent = "丰海科技正式成为美的集团供应商";
        this.$refs.firstChild2.style.backgroundImage = "none";
      },
      hp4() {
        this.$refs.firstChild3.style.height = ".9792rem";
        this.$refs.firstChild3.style.fontSize = ".1667rem";
        this.$refs.pElement3.style.color = "white";
        this.$refs.pElement3.style.width = "2.0833rem";
        this.$refs.pElement3.style.whiteSpace = "normal";
        this.$refs.span3.style.fontSize = ".1667rem";
        this.$refs.span3.style.color = "#fff";
        this.$refs.span3.style.borderBottom = ".0104rem solid #FFFFFF";

        this.$refs.firstChild3.style.backgroundImage = `url(${require('../assets/xinw.png')})`;
        this.$refs.pElement3.style.height = ".5729rem";
        this.$refs.pElement3.style.lineHeight = ".2604rem";



        this.$refs.pElement0.textContent = "丰海科技荣膺国家级专精特新“小巨人”企业称号";
      },
      hl4() {
        this.$refs.pElement3.style.color = "#000";
        this.$refs.firstChild3.style.height = ".625rem";
        this.$refs.firstChild3.style.fontSize = ".125rem";
        this.$refs.span3.style.fontSize = ".125rem";
        this.$refs.span3.style.color = "#898989";
        this.$refs.span3.style.borderBottom = ".0104rem solid #898989";
        this.$refs.pElement3.style.height = ".2083rem";
        this.$refs.pElement3.style.overflow = "hidden"; // Hide any overflowing content
        this.$refs.pElement3.style.textOverflow = "ellipsis"; // Add an ellipsis (...) for text that overflows
        this.$refs.pElement3.style.whiteSpace = "nowrap";
        this.$refs.pElement3.style.lineHeight = ".2083rem";
        this.$refs.pElement3.textContent = "丰海科技荣膺国家级专精特新“小巨人”企业称号";
        this.$refs.firstChild3.style.backgroundImage = "none";
      },
      hp5() {
        this.$refs.firstChild4.style.height = ".9792rem";
        this.$refs.firstChild4.style.fontSize = ".1667rem";
        this.$refs.pElement4.style.color = "white";
        this.$refs.pElement4.style.width = "2.0833rem";
        this.$refs.pElement4.style.whiteSpace = "normal";
        this.$refs.span4.style.fontSize = ".1667rem";
        this.$refs.span4.style.color = "#fff";
        this.$refs.span4.style.borderBottom = ".0104rem solid #FFFFFF";

        this.$refs.firstChild4.style.backgroundImage = `url(${require('../assets/xinw.png')})`;
        this.$refs.pElement4.style.height = ".5729rem";
        this.$refs.pElement4.style.lineHeight = ".2604rem";



        this.$refs.pElement0.textContent = "创新引领发展，丰海科技再获新专利";
      },
      hl5() {
        this.$refs.pElement4.style.color = "#000";
        this.$refs.firstChild4.style.height = ".625rem";
        this.$refs.firstChild4.style.fontSize = ".125rem";
        this.$refs.span4.style.fontSize = ".125rem";
        this.$refs.span4.style.color = "#898989";
        this.$refs.span4.style.borderBottom = ".0104rem solid #898989";
        this.$refs.pElement4.style.height = ".2083rem";
        this.$refs.pElement4.style.overflow = "hidden"; // Hide any overflowing content
        this.$refs.pElement4.style.textOverflow = "ellipsis"; // Add an ellipsis (...) for text that overflows
        this.$refs.pElement4.style.whiteSpace = "nowrap";
        this.$refs.pElement4.style.lineHeight = ".2083rem";
        this.$refs.pElement4.textContent = "创新引领发展，丰海科技再获新专利";
        this.$refs.firstChild4.style.backgroundImage = "none";
      },
      showDiv(index) {
        const subContainer = this.$refs.subContainer;
        subContainer.style.transform = `translateX(-${index * (2.310)}rem)`;
      },
      prevSlide() {
        if (this.currentIndex > 0) {
          this.currentIndex--;
          this.showDiv(this.currentIndex);
        }
      },
      nextSlide() {
        if (this.currentIndex < 2) {
          this.currentIndex++;
          this.showDiv(this.currentIndex);
        }
      },

      trgoTo() {
        this.imgSrc = require('../assets/xxLogo2.png');
      },
      trgoLve() {
        this.imgSrc = require('../assets/xxLogo.png');
      },
      selectProject(project) {
        this.selectedLi = project;
        this.selectedProject = this.projects[project].name;
        this.selectedProjectLogo = this.projects[project].logo;
        this.selectedProjectImage = this.projects[project].image;
      },
      navigateToProject(selectedLi) {
        if (selectedLi === '交通雷达') {
          this.$router.push({ path: '/casePresentation', query: { tab: 'first' } });

        } else if (selectedLi === '雷达通信模组') {
          this.$router.push({ path: '/casePresentation', query: { tab: 'six' } });
        } else if (selectedLi === '智能收费亭') {
          this.$router.push({ path: '/casePresentation', query: { tab: 'seven' } });
        } else if (selectedLi === '交通诱导系列') {
          this.$router.push({ path: '/casePresentation', query: { tab: 'eight' } });
        } else if (selectedLi === '可变信息情报板') {
          this.$router.push({ path: '/casePresentation', query: { tab: 'third' } });
        } else if (selectedLi === '收费车道设备系列') {
          this.$router.push({ path: '/casePresentation', query: { tab: 'nine' } });
        } else if (selectedLi === 'LED显示标志系列') {
          this.$router.push({ path: '/casePresentation', query: { tab: 'four' } });
        } else if (selectedLi === 'LED照明系列') {
          this.$router.push({ path: '/casePresentation', query: { tab: 'Five' } });
        }
        // 添加其他li对应的路由跳转逻辑
      },


      slideElementsOut(callback) {
        const elementsToAnimate = this.$el.querySelectorAll('.slide-in'); // 选择所有需要动画的元素进行滑出
        if (elementsToAnimate.length === 0) {
          return callback(); // 如果没有元素需要动画，直接执行回调
        }
        elementsToAnimate.forEach(el => {
          el.style.transition = 'transform 0.3s ease-in-out';
          el.style.transform = 'translateX(-100%)'; // 执行滑出
        });
        // 所有动画完成后执行回调
        setTimeout(() => {
          callback && callback();
        }, 300);
      },
      // 
      selectTab(index) {
        this.slideElementsOut(() => { // 先执行滑出
          this.progressBarWidth = 0;
          if (this.interval) clearInterval(this.interval);
          // 在内容彻底滑出之后再执行内容切换
          this.currentTab = index;
          this.tabContent = this.tabs[index];
          this.startProgressBar();
          // 新内容准备好后，执行滑入

          this.$nextTick(() => { // 确保DOM已经更新
            this.slideElementsIn();
          });
        });
      },







      startProgressBar() {
        if (this.interval) clearInterval(this.interval);
        this.interval = setInterval(this.updateProgressBar, 100);
      },

      handleMouseOver() {
        this.paragraphStyle.marginBottom = '.0938rem';
      },
      handleMouseOut() {
        this.paragraphStyle.marginBottom = '.0521rem';
      },
      changeImagePath() {
        this.imagePath = require("../assets/xxLogo2.png");
      },
      changeImagPath() {
        this.imagePathed = require("../assets/xxLogo2.png");
      },
      resetImagePath() {
        this.imagePath = require("../assets/xxLogo.png");
      },
      resetImagPath() {
        this.imagePathed = require("../assets/xxLogo.png");
      },
      goTosam() {
        this.$router.push({ path: '/soluTion' });
        const windowHeight = window.innerHeight; // 获取窗口高度
        const targetOffsetTop = 2000; // 设置目标元素距离页面顶部的偏移量
        const scrollTo = targetOffsetTop - (windowHeight / 2); // 计算滚动距离

        // 添加动画效果
        document.documentElement.style.scrollBehavior = 'smooth';
        document.documentElement.style.opacity = 0.6; // 设置透明度为0
        window.scrollTo(0, scrollTo); // 滚动到目标位置

        // 恢复默认的滚动行为
        setTimeout(() => {
          document.documentElement.style.scrollBehavior = 'auto';
          document.documentElement.style.opacity = 1; // 设置透明度为1
        }, 1000);
      },
      goTosam2() {
        // this.$router.push({ path: '/samCov' });
        this.$router.push({ path: '/soluTion' });
        const windowHeight = window.innerHeight; // 获取窗口高度
        const targetOffsetTop = 2400; // 设置目标元素距离页面顶部的偏移量
        const scrollTo = targetOffsetTop - (windowHeight / 2); // 计算滚动距离

        // 添加动画效果
        document.documentElement.style.scrollBehavior = 'smooth';
        document.documentElement.style.opacity = 0.6; // 设置透明度为0
        window.scrollTo(0, scrollTo); // 滚动到目标位置

        // 恢复默认的滚动行为
        setTimeout(() => {
          document.documentElement.style.scrollBehavior = 'auto';
          document.documentElement.style.opacity = 1;
        }, 1000);
      },
      goTosam3() {
        this.$router.push({ path: '/soluTion' });
        const windowHeight = window.innerHeight; // 获取窗口高度
        const targetOffsetTop = 2800; // 设置目标元素距离页面顶部的偏移量
        const scrollTo = targetOffsetTop - (windowHeight / 2); // 计算滚动距离

        // 添加动画效果
        document.documentElement.style.scrollBehavior = 'smooth';
        document.documentElement.style.opacity = 0.6; // 设置透明度为0
        window.scrollTo(0, scrollTo); // 滚动到目标位置

        // 恢复默认的滚动行为
        setTimeout(() => {
          document.documentElement.style.scrollBehavior = 'auto';
          document.documentElement.style.opacity = 1; // 设置透明度为1
        }, 1000);
      },
      goTosama() {
        this.$router.push({ path: '/soluTion' });
        const windowHeight = window.innerHeight; // 获取窗口高度
        const targetOffsetTop = 3200; // 设置目标元素距离页面顶部的偏移量
        const scrollTo = targetOffsetTop - (windowHeight / 2); // 计算滚动距离

        // 添加动画效果
        document.documentElement.style.scrollBehavior = 'smooth';
        document.documentElement.style.opacity = 0.6; // 设置透明度为0
        window.scrollTo(0, scrollTo); // 滚动到目标位置

        // 恢复默认的滚动行为
        setTimeout(() => {
          document.documentElement.style.scrollBehavior = 'auto';
          document.documentElement.style.opacity = 1; // 设置透明度为1
        }, 1000);
      },
      chaImg(event) {
        const img = event.currentTarget.querySelector('img');
        img.style.transition = "0.5s"; // 设置动画过渡时间为0.5秒
        img.style.transform = "scale(1.2)"; // 设置图片放大1.2倍
        img.style.overflow = "hidden"; // 超出部分隐藏
        const pk = event.currentTarget.querySelector('div p');
        pk.style.fontSize = .1354 + "rem";
        // pk.style.color="#1C4DF0";
        pk.style.opacity = 1
        pk.style.color = "#ededed";

        pk.style.filter = 'brightness(15);'

        const pm = event.currentTarget.querySelector('div');
        // pm.style.borderRadius="30% 30% 0 0";
        // pm.style.background = "linear-gradient(180deg, rgba(55, 64, 94, 0.5) 0%, rgba(55, 64, 94, 0.5) 100%)";
        pm.style.opacity = 0.8
        const pb = event.currentTarget.querySelector('.pb');

        const ko = event.currentTarget.querySelector('.ko');
        ko.style.fontSize = '.1406rem';
        pb.style.fontWeight = "bold"
        pb.style.opacity = 1
        // pb.style.fontSize = .0781+ "rem"
      },
      chaoutImg(event) {
        const ko = event.currentTarget.querySelector('.ko');
        ko.style.fontSize = '.1302rem';
        const img = event.currentTarget.querySelector('img');

        img.style.transition = "0.5s"; // 清除过渡效果

        img.style.transform = ""; // 清除变换效果

        img.style.overflow = ""; // 清除overflow属性

        // const pm = event.currentTarget.querySelector('div');
        // pm.style.opacity = 0.85;
        // pm.background = "linear-gradient(180deg, rgba(255,255,255,0.5) 0%, #37405E 100%);"

        const pb = event.currentTarget.querySelector('.pb');
        pb.style.fontWeight = null;
        pb.style.opacity = 0.85;
        pb.style.fontSize = .0729 + "rem";
        pb.style.color = "#fff";

        const pk = event.currentTarget.querySelector('div p');
        pk.style.fontSize = .125 + "rem";
        pk.style.opacity = 0.85
        pk.style.color = "#fff";
      },

      changeImage(index) {
        this.currentImage = this.images[index];
      },
      handleScroll() {
        const element = document.querySelector('.page2');
        if (element) {
          const position = element.getBoundingClientRect();
          const windowHeight = window.innerHeight;

          if (position.top < windowHeight) {
            this.show = true;
            window.removeEventListener('scroll', this.handleScroll);
          }
        }
      },
      goToPro1() {
        this.$router.push({ path: '/product-center', query: { tab: 'eight' } });
        const windowHeight = window.innerHeight; // 获取窗口高度
        const targetOffsetTop = 900; // 设置目标元素距离页面顶部的偏移量
        const scrollTo = targetOffsetTop - (windowHeight / 2); // 计算滚动距离
        window.scrollTo(0, scrollTo); // 滚动到目标位置

      },
      goToPro2() {
        this.$router.push({ path: '/product-center', query: { tab: 'seven' } });
        const windowHeight = window.innerHeight; // 获取窗口高度
        const targetOffsetTop = 900; // 设置目标元素距离页面顶部的偏移量
        const scrollTo = targetOffsetTop - (windowHeight / 2); // 计算滚动距离
        window.scrollTo(0, scrollTo); // 滚动到目标位置

      },
      goToPro3() {
        this.$router.push({ path: '/product-center', query: { tab: 'first' } });
        const windowHeight = window.innerHeight; // 获取窗口高度
        const targetOffsetTop = 900; // 设置目标元素距离页面顶部的偏移量
        const scrollTo = targetOffsetTop - (windowHeight / 2); // 计算滚动距离
        window.scrollTo(0, scrollTo); // 滚动到目标位置

      },
      goToPro51() {
        this.$router.push({ path: '/product-center', query: { tab: 'third' } });
        const windowHeight = window.innerHeight; // 获取窗口高度
        const targetOffsetTop = 900; // 设置目标元素距离页面顶部的偏移量
        const scrollTo = targetOffsetTop - (windowHeight / 2); // 计算滚动距离
        window.scrollTo(0, scrollTo); // 滚动到目标位置

      },
      goToPro53() {
        this.$router.push({ path: '/product-center', query: { tab: 'four' } });
        const windowHeight = window.innerHeight; // 获取窗口高度
        const targetOffsetTop = 900; // 设置目标元素距离页面顶部的偏移量
        const scrollTo = targetOffsetTop - (windowHeight / 2); // 计算滚动距离
        window.scrollTo(0, scrollTo); // 滚动到目标位置

      },
      goToPro52() {
        this.$router.push({ path: '/product-center', query: { tab: 'nine' } });
        const windowHeight = window.innerHeight; // 获取窗口高度
        const targetOffsetTop = 900; // 设置目标元素距离页面顶部的偏移量
        const scrollTo = targetOffsetTop - (windowHeight / 2); // 计算滚动距离
        window.scrollTo(0, scrollTo); // 滚动到目标位置

      },
      playVideo() {
        const video = this.$refs.video;
        video.play().catch(error => {
          console.error('Failed to play video:', error);
        });
      },
     

      goToPro4() {
        this.$router.push({ path: '/product-center', query: { tab: 'six' } });
        const windowHeight = window.innerHeight; // 获取窗口高度
        const targetOffsetTop = 900; // 设置目标元素距离页面顶部的偏移量
        const scrollTo = targetOffsetTop - (windowHeight / 2); // 计算滚动距离
        window.scrollTo(0, scrollTo); // 滚动到目标位置

      },
      goToPro5() {
        this.$router.push({ path: '/product-center', query: { tab: 'Five' } });
        const windowHeight = window.innerHeight; // 获取窗口高度
        const targetOffsetTop = 900; // 设置目标元素距离页面顶部的偏移量
        const scrollTo = targetOffsetTop - (windowHeight / 2); // 计算滚动距离
        window.scrollTo(0, scrollTo); // 滚动到目标位置

      },
      goToPro6() {
        this.$router.push({ path: '/millimeterWaveRadar1' });
        const windowHeight = window.innerHeight; // 获取窗口高度
        const targetOffsetTop = 900; // 设置目标元素距离页面顶部的偏移量
        const scrollTo = targetOffsetTop - (windowHeight / 2); // 计算滚动距离
        window.scrollTo(0, scrollTo); // 滚动到目标位置

      },
      goToPro7() {
        this.$router.push({ path: '/inTrans8' });
        const windowHeight = window.innerHeight; // 获取窗口高度
        const targetOffsetTop = 900; // 设置目标元素距离页面顶部的偏移量
        const scrollTo = targetOffsetTop - (windowHeight / 2); // 计算滚动距离
        window.scrollTo(0, scrollTo); // 滚动到目标位置

      },
   
      goToAboutPage() {
        this.$router.push({ path: '/aboutUs', query: { tab: 1 } });
        const windowHeight = window.innerHeight; // 获取窗口高度
        const targetOffsetTop = 900; // 设置目标元素距离页面顶部的偏移量
        const scrollTo = targetOffsetTop - (windowHeight / 2); // 计算滚动距离
        window.scrollTo(0, scrollTo); // 滚动到目标位置
      },

      goToAboutPage16() {
        this.$router.push({ path: '/aboutUs', query: { tab: 'eight' } });
        const windowHeight = window.innerHeight; // 
        const targetOffsetTop = 1000; // 设置目标元素距离页面顶部的偏移量
        const scrollTo = targetOffsetTop - (windowHeight / 2);
        window.scrollTo(0, scrollTo); //
      },
      goToNewPage() {
        this.$router.push({ path: '/newsCentres' });
        //   const windowHeight = window.innerHeight; // 获取窗口高度
        // const targetOffsetTop = 900; // 设置目标元素距离页面顶部的偏移量
        // const scrollTo = targetOffsetTop - (windowHeight / 2); // 计算滚动距离
        // window.scrollTo(0, scrollTo); // 滚动到目标位置

      },
      gotoBuy1() {
        this.$router.push({ path: '/casePresentation', query: { tab: 'first' } });
      },
      gotoBuy2() {
        this.$router.push({ path: '/casePresentation', query: { tab: 'six' } });
      },
      gotoBuy3() {
        this.$router.push({ path: '/casePresentation', query: { tab: 'seven' } });
      },
      gotoBuy4() {
        this.$router.push({ path: '/casePresentation', query: { tab: 'eight' } });
      },
      gotoBuy5() {
        this.$router.push({ path: '/casePresentation', query: { tab: 'third' } });
      },
      gotoBuy6() {
        this.$router.push({ path: '/casePresentation', query: { tab: 'nine' } });
      },
      gotoBuy7() {
        this.$router.push({ path: '/casePresentation', query: { tab: 'four' } });
      },
      gotoBuy8() {
        this.$router.push({ path: '/casePresentation', query: { tab: 'Five' } });
      },




    },

  };
</script>
<style scoped>
  .container {
    background-color: #F0F0F0;
    width: 6.9896rem;
    height: 3.125rem;
    overflow: hidden;
    position: relative;
    margin-top: .4167rem;
  }

  .sub-container {
    display: flex;
    transition: transform 0.5s ease;
    margin-top: .5208rem;
  }

  .sub-div {
    width: 2.2396rem;
    height: 2.1198rem;
    margin-right: .0677rem;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: .125rem;
    flex-shrink: 0;
  }

  button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
  }

  #prevBtn {
    left: -0.0521rem;
  }

  #nextBtn {
    right: -0.0521rem;
  }



  .anliUl {
    list-style: none;
    font-size: .1042rem;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    width: 100%;
  }

  .anliUl li {
    width: .9635rem;
    height: .1823rem;
    border: 1px solid #828282;
    line-height: .1823rem;
    text-align: center;
    box-sizing: border-box;
    margin-top: .224rem;
    font-family: '微软雅黑';
  }

  .anliUl li.active {
    background-color: #407BF5;
    color: #fff;
  }

  .anli {
    width: 100%;
    height: 3.4167rem;
    background-image: url('../assets/1343副本.png');
    display: flex;
    justify-content: center;
    align-items: center;
    background-size: contain;
  }

  .anlixiao {
    width: 4.0938rem;
    height: 2.5938rem;
    background-size: contain;
    margin-left: .1563rem;
    border-radius: 0px .0781rem .0781rem 0;
    overflow: hidden;
  }

  .oodiv {
    background-image: url('../assets/fenghai.png')
  }

  .obo {
    background-image: url(../assets/logo7.png);
    background-repeat: repeat;
    background-size: auto;
    background-position: -0.3125rem .2604rem;
  }

  /*  */

  .mainv1 {
    width: 1.6667rem;
    height: 2.0833rem;
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    /* 隐藏溢出部分 */
  }

  .mainv1 p {
    display: none;

  }

  .mainv1:hover p {
    display: block;

  }


  .mainv2 {
    width: 1.6667rem;
    height: 2.0833rem;
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: hidden;

  }

  .mainv2 p {
    display: none;

  }

  .mainv2:hover p {
    display: block;

  }


  /* @media screen and (max-width: 7.4219rem) {
    .chanpin{
     transform: scale(0.78);
    }
    .anli{
      transform: scale(0.78);

    }


  
  
} */

  /* @media screen and (min-width: 7.4271rem) and (max-width: 7.8646rem) {
  .chanpin{
     transform: scale(0.90);
    }

    .anli{
      transform: scale(0.90);

    }
 
  
 
} */

  .mainv3 {
    width: 1.6667rem;
    height: 2.0833rem;
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: hidden;

  }

  .mainv3 p {
    display: none;

  }

  .mainv3:hover p {
    display: block;

  }

  .mainv4 {
    width: 1.6667rem;
    height: 2.0833rem;
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: hidden;

  }

  .mainv4 p {
    display: none;

  }

  .mainv4:hover p {
    display: block;

  }




  .tdesign-demo-block--swiper {
    width: 6.8542rem;
    height: 2.2708rem;
    margin: 0 auto;
    margin-bottom: .4167rem;
    margin-top: .1563rem;
  }


  /* .t-swiper-arrow-right{
    background-color: red;
  } */


  .oDiv ul li {
    display: list-item;
    text-indent: -1em;
    padding: .0208rem;
    font-size: .0833rem;
    font-family: 'HarmonyOS_Sans_SC_Regular';
    font-weight: 400;

    line-height: .125rem;
    color: #666;
  }

  .oDiv ul {
    left: .0521rem;
    list-style-type: circle;
  }

  .size2 {
    width: .6667rem;
    height: .2344rem;
    font-size: .1458rem;
    font-family: PingFang SC, PingFang SC;
    font-weight: bold;
    color: #5B5B5B;
  }

  .size {
    width: .0625rem;
    height: .0938rem;
  }

  .selected {
    /* 添加选中状态的样式 */
    color: #95B5F9
  }

  .size {
    width: 1.5521rem;
    height: .1875rem;
    font-size: .1667rem;
    font-family: Arial, Arial;
    font-weight: 400;
    color: #1C4DF0;
    line-height: .4167rem;
  }


  .hover-div {
    overflow: hidden;
  }


  .hover-div p {
    font-size: .125rem;
    opacity: 0.85;
    font-weight: bold;
    color: #FFFFFF;
    font-family: PingFang SC, PingFang SC;

  }






  .page2 {

    position: relative;
  }

  /*

*/

  .el-carousel__item:nth-child(2n) {
    background-color: #FFF;

  }

  .el-carousel__item:nth-child(2n+1) {
    background-color: #FFF;

  }

  .newcen /deep/ .el-carousel__arrow--right {
    transform: translateX(1.4583rem);
    background-image: url(../assets/1818.png);
    background-size: 100%;
  }

  .newcen /deep/ .el-carousel__arrow--left {

    background-image: url(../assets/1817.png);
    background-size: 100%;
  }



  .page2 p {
    margin: 0;
  }

  .slide-into {
    position: absolute;
    top: .8229rem;
    opacity: 0;
    transform: translateX(-0.2604rem);
    transition: opacity 3s, transform 3s;
  }


  .slide-into-enter {
    opacity: 0;
    transform: translateX(-0.2604rem);
  }

  .slide-into-enter-active {
    opacity: 1;
    transform: translateX(0);
  }

  .show .slide-into {
    opacity: 1;
    transform: translateX(0);
  }

  @keyframes slideInto {
    0% {
      opacity: 0;
      transform: translateX(-0.2604rem);
    }

    100% {
      opacity: 1;
      transform: translateX(0);
    }
  }

  .oodiv {
    background-image: url(../assets/456125.png);
  }

  .box {
    margin: 0;
    padding: 0;
    width: 100%;
    height: .4167rem;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .centered-ul {
    display: flex;
    justify-content: center;
    align-items: center;
    height: .4167rem;
    background-color: white;
    list-style: none;
    padding: 0;
  }

  .centered-li {
    width: .5208rem;
    text-align: center;
  }

  .palo {
    background-image: url(../assets/101.png);
  }

  .logo {
    position: absolute;
    left: 12.92%;
    display: block;
    height: .2083rem;
    /* vertical-align: middle; */
  }

  .newcen {
    background-image: url(../assets/newge.png);
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: center -0.3229rem;



  }

  .page-home /deep/.t-swiper__arrow svg.t-icon {
    /* background-color: #427CF4; */


  }

  .kk {
    position: absolute;
    right: .4219rem;
    height: .4167rem;
    display: flex;
    align-items: center;
  }

  span {
    height: .2083rem;
    font-size: .0729rem;
    margin-right: .0313rem;
  }

  .carousel {
    width: 100%;
    height: 3.3333rem;
    background-color: white;
  }

  .carousel2 {
    width: 100%;
    height: 3.5313rem;
    background-color: #EDEDED;
  }

  .page3 {
    background-image: url(../assets/logo7.png);
  }

  .hover1-div P {
    color: #003288;
    font-family: PingFang SC, PingFang SC;
  }

  .ooodiv p {
    line-height: .1563rem;
  }

  .page-home /deep/ .t-tabs__nav-item-wrapper {
    font-size: .1042rem;
    font-family: 'HarmonyOS_Sans_SC_Regular';
    font-weight: 400;
  }

  .cus-swi {
    height: 2.2708rem;
  }

  .dio:hover {
    cursor: pointer;
  }

  .tab-container {
    width: 25%;
    position: relative;
  }

  .progress-bar {
    position: absolute;
    top: 0;
    left: 0;
    height: .0052rem;
    background-color: red;
  }

  @font-face {
    font-family: 'YouSheBiaoTiHei';
    src: url('../assets/font/YouSheBiaoTiHei.ttf') format('truetype');
   
  }
 

  .tdesign-demo-block--swiper {
    width: 6.8542rem;
    margin: 0 auto;
    margin-bottom: .4167rem;
    margin-top: .1563rem;
  }


  /* .t-swiper-arrow-right{
    background-color: red;
  } */



  .slide-in-enter {
    opacity: 0;
    transform: translateX(-0.2604rem);
  }

  .hover-div div p:first-child {

    font-family: source-serif-pro, serif;

  }

  .slide-in-enter-active {
    opacity: 1;
    transform: translateX(0);
  }

  .show .slide-in {
    opacity: 1;
    transform: translateX(0);
  }

  @keyframes slideIn {
    0% {
      opacity: 0;
      transform: translateX(-0.2604rem);
    }

    100% {
      opacity: 1;
      transform: translateX(0);
    }
  }

  .oodiv {
    background-image: url(../assets/456125.png);
  }

  .box {
    margin: 0;
    padding: 0;
    width: 100%;
    height: .4167rem;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .centered-ul {
    display: flex;
    justify-content: center;
    align-items: center;
    height: .4167rem;
    background-color: white;
    list-style: none;
    padding: 0;
  }

  .centered-li {
    width: .5208rem;
    text-align: center;
  }

  .palo {
    background-image: url(../assets/101.png);
  }

  .p1 {
    
  }

  .logo {
    position: absolute;
    left: 12.92%;
    display: block;
    height: .2083rem;
    /* vertical-align: middle; */
  }

  .newcen {
    background-image: url(../assets/newge.png);
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: center -0.3229rem;

  }

  .kk {
    position: absolute;
    right: .4219rem;
    height: .4167rem;
    display: flex;
    align-items: center;
  }

  span {
    height: .2083rem;
    font-size: .0729rem;
    margin-right: .0313rem;
  }

  .carousel {
    width: 100%;
    height: 3.3333rem;
    background-color: white;
  }

  /* .slide-in {
  transform: translateX(100%);
} */

  .carousel2 {
    width: 100%;
    height: 3.5313rem;
    background-color: #EDEDED;
  }

  .page3 {
    background-image: url(../assets/logo7.png);
  }

  .hover1-div P {
    color: #003288;
    font-family: PingFang SC, PingFang SC;
  }

  .ooodiv p {
    line-height: .1563rem;
  }

  .page-home /deep/ .t-tabs__nav-item-wrapper {
    font-size: .1042rem;
    font-family: 'HarmonyOS_Sans_SC_Regular';
    font-weight: 400;
  }

  .dio:hover {
    cursor: pointer;
  }

  .tab-container {
    width: 25%;
    position: relative;
  }

  .progress-bar {
    position: absolute;
    top: 0;
    left: 0;
    height: .0052rem;
    background-color: red;
  }

  .content-container {
    width: 100%;

    height: 2.6042rem;

    position: relative;

    overflow: hidden;
    /* 隐藏超出部分 */
  }

  .newcen /deep/ .el-carousel__indicators {
    transform: translateX(.7813rem);
  }

  .slide-left-enter-active,
  .slide-left-leave-active {
    transition: transform 1s;
    position: absolute;
    width: 100%;
  }

  .slide-left-enter,
  .slide-left-leave-to {
    position: absolute;
    width: 100%;
  }

  .slide-left-enter {
    transform: translateX(100%);
  }

  .slide-left-leave-to {
    transform: translateX(-100%);
  }

  .slide-left-enter-active {
    z-index: 1;
  }

  .slide-left-leave-active {
    z-index: 2;

  }

  .slide-left-enter,
  .slide-left-leave-to {
    position: absolute;
    width: 100%;
  }

  .content-container {
    overflow: hidden;
    filter: brightness(103%);
  }

  .content-container:hover {
    filter: brightness(108%);
  }

  .content-container:hover p.slide-in {
    margin-bottom: .5208rem;
    transition: transform 0.3s ease;
  }

  #opop p:hover {
    color: #267BC0
  }

  .ko {
    font-size: .1302rem;
  }
</style>

<style>
  ::-webkit-scrollbar {
    width: .0521rem;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #cfcfcf;
  }

  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
</style>